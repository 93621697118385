<template>
  <div>
    <a-col>
      <a-input-search
        placeholder="Поиск по названию"
        style="width: 200px"
        @input="onSearch"
        :loading="loading" />
    </a-col>
    <h2 :style="{
      textAlign: 'center',
      margin: '2em 1em',
    }"
        v-if="warehouse">Остаток Склада {{ warehouse.Name }}</h2>
    <a-row>
      <a-col :span="12">
        <ul class="custom-list">
          <li>
            <b>Общее количество</b>: {{ totalAmount }}
          </li>
        </ul>
      </a-col>
      <a-col :span="12">
        <ul class="custom-list">
          <li>
            <b>Общая сумма</b>: {{ totalSum }}
          </li>
        </ul>
      </a-col>
    </a-row>
    <a-table
      :columns="columns"
      :rowKey="record => record.Id"
      :dataSource="details"
      :pagination="false"
      :loading="loading"
    >
      <template slot="Price" slot-scope="price">
        {{ price.toFixed(0) }}
      </template>
      <template slot="Sum" slot-scope="sum">
        {{ sum.toFixed(0) }}
      </template>
      <template slot="Attributes" slot-scope="record, item">
          <a-button v-on:click="productOnModal(item)">
            Просмотреть продукт
          </a-button>
      </template>
    </a-table>
    <a-modal
      title=""
      :visible="modalVisible"
      @cancel="closeModal"
    >
      <a-table
        style="margin-top: 1em"
        :columns="attributeModal"
        :data-source="attributeModalData"
        :pagination="false">
        <template slot="Attributes" slot-scope="item">
          <div v-for="(attribute, index) in item" :key="index" style="display: inline-block">
            <b style="text-transform: capitalize">
              {{ attribute.Name }}</b>: {{ attribute.Value }}
            &nbsp;
          </div>
        </template>
      </a-table>
      <template slot="footer"><div></div></template>
    </a-modal>
  </div>
</template>
<script>
import _ from 'lodash';

export default {
  data: () => ({
    details: undefined,
    warehouse: undefined,
    totalAmount: undefined,
    totalSum: undefined,
    loading: false,
    modalVisible: false,
    attributeModal: [
      {
        title: 'Атрибуты',
        dataIndex: 'Attributes',
        scopedSlots: { customRender: 'Attributes' },
      },
    ],
    attributeModalData: [],
    columns: [
      {
        title: 'Наименование',
        dataIndex: 'ProductName',
      },
      {
        title: 'Кол.во',
        dataIndex: 'Amount',
      },
      {
        title: 'Ед.изм',
        dataIndex: 'MeasurementName',
      },
      {
        title: 'Цена',
        dataIndex: 'Price',
        scopedSlots: { customRender: 'Price' },
      },
      {
        title: 'Сумма',
        dataIndex: 'Sum',
        scopedSlots: { customRender: 'Sum' },
      },
      {
        title: 'Атрибуты',
        dataIndex: 'Attributes',
        scopedSlots: { customRender: 'Attributes' },
      },
    ],
  }),
  created() {
    this.fetch();
  },
  methods: {
    productOnModal(item) {
      const filters = {
        WarehouseId: this.warehouse.Id,
        ProductId: item.ProductId,
      };
      const query = Object.keys(filters)
        .map((key) => filters[key] && `${key}=${filters[key]}`)
        .join('&');
      this.$api.getDetails(query, (response) => {
        const { data } = response.data;
        this.modalVisible = true;
        this.attributeModalData = data;
      });
    },
    closeModal() {
      this.modalVisible = false;
    },
    grouped(arrayData) {
      return _.chain(arrayData)
        .groupBy('Name')
        .map((value, key) => ({ Name: key, Value: value }))
        .value();
    },
    fetch() {
      this.loading = true;
      const { warehouseId } = this.$router.currentRoute.params;
      const query = `?WarehouseId=${warehouseId}`;
      this.$api.getBalance(query, (response) => {
        const { data } = response;
        this.details = data.data;
        this.warehouse = data.Warehouse;
        this.totalAmount = data.TotalAmount;
        this.totalSum = data.TotalSum;
        this.loading = false;
      });
    },
    onSearch(e) {
      const { value } = e.target;
      this.loading = true;
      const { warehouseId } = this.$router.currentRoute.params;
      const query = `?WarehouseId=${warehouseId}&filter[Name]=${value}`;
      this.$api.getBalance(query, (response) => {
        const { data } = response;
        this.details = data.data;
        this.warehouse = data.Warehouse;
        this.totalAmount = data.TotalAmount;
        this.totalSum = data.TotalSum;
        this.loading = false;
      });
    },
  },
};
</script>
